import React, { Component } from "react";
import { Link } from "@reach/router";
import data from "shared/data.json";

import Header from "shared/components/Header.jsx";
import Rotate from "shared/components/Rotate.jsx";

import styles from "pages/Legal.module.scss";
import Structure from "shared/styles/Structure.module.scss";
import Icons from "shared/styles/Icons.module.scss";
import Typography from "shared/styles/Typography.module.scss";
import parse from "html-react-parser";

function delayUnmounting(Component) {
  return class extends React.Component {
    state = {
      shouldRender: this.props.isMounted,
    };

    componentDidUpdate(prevProps) {
      if (prevProps.isMounted && !this.props.isMounted) {
        setTimeout(() => {
          this.setState({ shouldRender: false });
        }, this.props.delayTime);
      } else if (!prevProps.isMounted && this.props.isMounted) {
        this.setState({ shouldRender: true });
      }
    }

    render() {
      return this.state.shouldRender ? <Component {...this.props} /> : null;
    }
  };
}

function Content(props) {
  return (
    <div className={` ${styles.content} `}>
      <p className={Typography.legal}>{parse(data.legal.body[0])}</p>
      <p className={Typography.legal}>{parse(data.legal.body[1])}</p>
      <p className={Typography.legal}>{parse(data.legal.body[2])}</p>
      <p className={Typography.legal}>{parse(data.legal.body[3])}</p>
      <Link
        className={styles.next}
        to="/contact"
        onClick={() => {
          props.updateLastAction(data.contact.headline);
        }}
      >
        {data.contact.headline} <span className={Icons.arrow} />
      </Link>
    </div>
  );
}

const DelayedComponent = delayUnmounting(Content);

class Legal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: true,
    };
  }

  componentDidMount() {
    this.setState({ isMounted: true });
    this.props.updateClickstream("Legal");
    window.console.log("Mounted");
  }

  componentWillUnmount() {
    window.console.log("Unmounted");
    window.console.log("");
  }

  render() {
    return (
      <div
        className={`${Structure.container} ${styles[this.state.contentClass]}`}
      >
        <Header updateClickstream={this.props.updateClickstream} />
        <Rotate />
        <DelayedComponent
          isMounted={this.state.isMounted}
          updateLastAction={this.props.updateLastAction}
        />
      </div>
    );
  }
}

export default Legal;
