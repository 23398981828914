import React, { Component } from "react";

import { navigate } from "@reach/router";
import data from "shared/data.json";

import Header from "shared/components/Header.jsx";
import Rotate from "shared/components/Rotate.jsx";

import styles from "pages/Home.module.scss";
import Structure from "shared/styles/Structure.module.scss";
import Icons from "shared/styles/Icons.module.scss";
import Typography from "shared/styles/Typography.module.scss";

function delayUnmounting(Component) {
  return class extends React.Component {
    state = {
      shouldRender: this.props.isMounted
    };

    componentDidUpdate(prevProps) {
      if (
        prevProps.isMounted &&
        !this.props.isMounted &&
        !this.props.activeAnimation
      ) {
        setTimeout(() => {
          this.setState({ shouldRender: false });
          if (this.props.navDirection === "up") {
            navigate(this.props.prevPath);
          } else if (this.props.navDirection === "down") {
            navigate(this.props.nextPath);
          }
        }, this.props.delayTime);
      } else if (!prevProps.isMounted && this.props.isMounted) {
        this.setState({ shouldRender: true });
      }
    }

    render() {
      return this.state.shouldRender ? <Component {...this.props} /> : null;
    }
  };
}

function Content(props) {
  return (
    <div className={styles.content}>
      <div className={` ${styles.left}`}>
        <h1>
          <strong className={Typography.lead}>{data.home.headline}</strong>
        </h1>
        <p className={Typography.lead}>{data.home.body[0]}</p>
        <p className={Typography.lead}>{data.home.body[1]}</p>
      </div>
      <div className={` ${styles.right} `}>
        <span className={styles.book} />
        <div className={styles.photos}>
          <span className={` ${styles.photo} ${styles.photo1} `} />
          <span className={` ${styles.photo} ${styles.photo2} `} />
          <span className={` ${styles.photo} ${styles.photo3} `} />
          <span className={` ${styles.photo} ${styles.photo4} `} />
          <span className={` ${styles.photo} ${styles.photo5} `} />
          <span className={` ${styles.photo} ${styles.photo6} `} />
          <span className={` ${styles.photo} ${styles.photo7} `} />
          <span className={` ${styles.photo} ${styles.photo8} `} />
        </div>
      </div>
    </div>
  );
}

const DelayedComponent = delayUnmounting(Content);

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeAnimation: true,
      isMounted: true
    };
    this.handleNavigation = this.handleNavigation.bind(this);
  }

  handleNavigation = e => {
    const window = e.currentTarget;

    if (window.pageYOffset < 1 && this.state.activeAnimation === false) {
      window.console.log("Can't Scroll Up, Only Down");
    } else if (window.pageYOffset > 1 && this.state.activeAnimation === false) {
      this.props.updateLastAction("scrolled");
      window.console.log("scrolling down");
      window.removeEventListener("scroll", this.handleNavigation);
      document.body.classList.remove("active");
      window.console.log("Stop Listening");
      this.setState(state => ({
        isMounted: false,
        contentClass: "exit",
        navDirection: "down"
      }));
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState(state => ({
      isMounted: true,
      contentClass: "enter",
      activeAnimation: true
    }));
    window.console.log("Mounted");
    this.props.updateClickstream(data.home.headline);
    setTimeout(() => {
      window.console.log("Start Listening");
      document.body.classList.add("active");
      window.scrollTo(0, 1);
      this.setState({ activeAnimation: false });
      window.addEventListener("scroll", this.handleNavigation);
    }, 3000);
  }

  forceNext = () => {
    if (!this.state.activeAnimation) {
      this.props.updateLastAction("click scrolled");
      window.console.log("clicked down");
      window.removeEventListener("scroll", this.handleNavigation);
      document.body.classList.remove("active");
      window.console.log("Stop Listening");
      this.setState(state => ({
        isMounted: false,
        contentClass: "exit",
        navDirection: "down"
      }));
    } else if (this.state.activeAnimation) {
      window.console.log("Animation Still In Progress");
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleNavigation);
    window.console.log("Unmounted");
    window.console.log("");
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div
        className={`${Structure.container} ${styles[this.state.contentClass]}`}
      >
        <Header updateClickstream={this.props.updateClickstream} />
        <Rotate />
        <DelayedComponent
          delayTime={3000}
          isMounted={this.state.isMounted}
          navDirection={this.state.navDirection}
          activeAnimation={this.state.activeAnimation}
          nextPath={this.props.nextPath}
          prevPath={this.props.prevPath}
          dataSrc={this.props.dataSrc}
          updateClickstream={this.props.updateClickstream}
        />
        <span onClick={this.forceNext} className={Icons.scrollDown}>
          Scroll for more
        </span>
      </div>
    );
  }
}

export default Home;
