import React, { Component } from "react";
import { Link } from "@reach/router";
import data from "shared/data.json";

// import ImgCalendar from "img/calendar.png";
// import ImgPhone from "img/phone.png";

import Header from "shared/components/Header.jsx";
import Rotate from "shared/components/Rotate.jsx";

import styles from "pages/Contact.module.scss";
import Structure from "shared/styles/Structure.module.scss";
import Icons from "shared/styles/Icons.module.scss";

function delayUnmounting(Component) {
  return class extends React.Component {
    state = {
      shouldRender: this.props.isMounted
    };

    componentDidUpdate(prevProps) {
      if (prevProps.isMounted && !this.props.isMounted) {
        setTimeout(() => {
          this.setState({ shouldRender: false });
        }, this.props.delayTime);
      } else if (!prevProps.isMounted && this.props.isMounted) {
        this.setState({ shouldRender: true });
      }
    }

    render() {
      return this.state.shouldRender ? <Component {...this.props} /> : null;
    }
  };
}

function Content(props) {
  return (
    <div className={` ${styles.content} `}>
      <h1>{data.contact.headline}</h1>
      <p>{data.contact.body}</p>

      <a
        className={styles.link}
        href={data.contact.schedule.url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          props.updateClickstream(
            data.contact.schedule.url,
            data.contact.schedule.copy,
            true
          );
        }}
      >
        <div className={` ${styles.calendar} `} />
        <span className={styles.copy}>
          {data.contact.schedule.copy}
          <span className={Icons.arrow} />
        </span>
      </a>

      <span className={styles.link}>
        <div className={` ${styles.phone} `} />
        <span className={styles.copy}>
          {data.contact.call[0]}
          <br />
          {data.contact.call[1]}
        </span>
      </span>

      <Link
        className={styles.next}
        to="/legal"
        onClick={() => {
          props.updateLastAction("Disclosures");
        }}
      >
        Disclosures <span className={Icons.arrow} />
      </Link>
    </div>
  );
}

const DelayedComponent = delayUnmounting(Content);

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: true
    };
  }

  componentDidMount() {
    this.setState({ isMounted: true });
    this.props.updateClickstream(data.contact.headline);
    window.console.log("Mounted");
  }

  componentWillUnmount() {
    window.console.log("Unmounted");
    window.console.log("");
  }

  render() {
    return (
      <div
        className={`${Structure.container} ${styles[this.state.contentClass]}`}
      >
        <Header updateClickstream={this.props.updateClickstream} />
        <Rotate />
        <DelayedComponent
          isMounted={this.state.isMounted}
          updateClickstream={this.props.updateClickstream}
          updateLastAction={this.props.updateLastAction}
        />
      </div>
    );
  }
}

export default Contact;
