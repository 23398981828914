import React, { Component } from "react";

import Structure from "shared/styles/Structure.module.scss";
import Icons from "shared/styles/Icons.module.scss";

class Header extends Component {
  render() {
    let ubsUrl = "http://ubs.com";
    return (
      <div className={`${Structure.header} `}>
        <span className={Icons.logo}>UBS</span>
        <a className={Icons.close}
          href={ubsUrl}
          onClick={() => this.props.updateClickstream(ubsUrl, 'Close Icon', true)}
        >
          Close
        </a>
      </div>
    );
  }
}

export default Header;
