import React, { Component } from "react";
import { Link, navigate, createHistory } from "@reach/router";
import data from "shared/data.json";

// import ImgTrophy from "img/trophy.png";
// import ImgChat from "img/chat.png";

import Header from "shared/components/Header.jsx";
import Rotate from "shared/components/Rotate.jsx";

import styles from "pages/TakeTheFirstStep.module.scss";
import Structure from "shared/styles/Structure.module.scss";
import Icons from "shared/styles/Icons.module.scss";

function delayUnmounting(Component) {
  return class extends React.Component {
    state = {
      shouldRender: this.props.isMounted
    };

    componentDidUpdate(prevProps) {
      if (
        prevProps.isMounted &&
        !this.props.isMounted &&
        !this.props.activeAnimation
      ) {
        setTimeout(() => {
          this.setState({ shouldRender: false });
          if (this.props.navDirection === "up") {
            navigate(this.props.prevPath);
          }
        }, this.props.delayTime);
      } else if (!prevProps.isMounted && this.props.isMounted) {
        this.setState({ shouldRender: true });
      }
    }

    render() {
      return this.state.shouldRender ? <Component {...this.props} /> : null;
    }
  };
}

function Content(props) {
  return (
    <div className={` ${styles.content} `}>
      <h1>{data.takeTheFirstStep.headline}</h1>

      <a
        className={styles.link}
        href={data.takeTheFirstStep.cta.url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          props.updateClickstream(
            data.takeTheFirstStep.cta.url,
            data.takeTheFirstStep.cta.copy,
            true
          );
        }}
      >
        <div className={` ${styles.trophy} `} />
        <span className={styles.copy}>
          {data.takeTheFirstStep.cta.copy}
          <span className={Icons.arrow} />
        </span>
      </a>
      <Link
        className={styles.link}
        to={data.takeTheFirstStep.contact.url}
        onClick={() => {
          props.updateLastAction(data.takeTheFirstStep.contact.copy);
        }}
      >
        <div className={` ${styles.chat} `} />
        <span className={styles.copy}>
          {data.takeTheFirstStep.contact.copy}
          <span className={Icons.arrow} />
        </span>
      </Link>
      <Link
        className={styles.next}
        to="/legal"
        onClick={() => {
          props.updateLastAction("Disclosures");
        }}
      >
        Disclosures <span className={Icons.arrow} />
      </Link>
    </div>
  );
}

const DelayedComponent = delayUnmounting(Content);

class TakeTheFirstStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeAnimation: true,
      isMounted: true
    };
    this.handleNavigation = this.handleNavigation.bind(this);

    this.history = createHistory(window);
    this.history.listen(e => {
      if (e.action === "POP") {
        window.removeEventListener("scroll", this.handleNavigation);
      }
    });
  }

  handleNavigation = e => {
    const window = e.currentTarget;

    if (window.pageYOffset < 1 && this.state.activeAnimation === false) {
      this.props.updateLastAction("scrolled");
      window.console.log("scrolling up");
      window.removeEventListener("scroll", this.handleNavigation);
      document.body.classList.remove("active");
      window.console.log("Stop Listening");
      this.setState(state => ({
        isMounted: false,
        contentClass: "exit",
        navDirection: "up"
      }));
    } else if (window.pageYOffset > 1 && this.state.activeAnimation === false) {
      window.console.log("Can't Scroll Down, Only Up");
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState(state => ({
      isMounted: true,
      contentClass: "enter",
      activeAnimation: true
    }));
    window.console.log("Mounted");
    this.props.updateClickstream(data.takeTheFirstStep.headline);
    setTimeout(() => {
      window.console.log("Start Listening");
      document.body.classList.add("active");
      window.scrollTo(0, 1);
      this.setState({ activeAnimation: false });
      window.addEventListener("scroll", this.handleNavigation);
    }, 2000);
  }

  forcePrevious = () => {
    if (!this.state.activeAnimation) {
      this.props.updateLastAction("click scrolled");
      window.console.log("clicked up");
      window.removeEventListener("scroll", this.handleNavigation);
      document.body.classList.remove("active");
      window.console.log("Stop Listening");
      this.setState(state => ({
        isMounted: false,
        contentClass: "exit",
        navDirection: "up"
      }));
    } else if (this.state.activeAnimation) {
      window.console.log("Animation Still In Progress");
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleNavigation);
    window.console.log("Unmounted");
    window.console.log("");
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div
        className={`${Structure.container} ${styles[this.state.contentClass]}`}
      >
        <span onClick={this.forcePrevious} className={Icons.scrollUp} />
        <Header updateClickstream={this.props.updateClickstream} />
        <Rotate />
        <DelayedComponent
          delayTime={2000}
          isMounted={this.state.isMounted}
          navDirection={this.state.navDirection}
          activeAnimation={this.state.activeAnimation}
          prevPath={this.props.prevPath}
          updateClickstream={this.props.updateClickstream}
          updateLastAction={this.props.updateLastAction}
        />
      </div>
    );
  }
}

export default TakeTheFirstStep;
